import styled from 'styled-components'

export const MarginWrapper = styled.div<{
  $margin?: string
  $marginVertical?: string
  $marginHorizontal?: string
  $marginBottom?: string
  $marginTop?: string
  $marginLeft?: string
  $marginRight?: string
  $padding?: string
  $paddingVertical?: string
  $paddingHorizontal?: string
  $paddingBottom?: string
  $paddingTop?: string
  $paddingLeft?: string
  $paddingRight?: string
}>`
  margin: ${({ $margin }) => $margin};
  margin: ${({ $marginVertical }) => `${$marginVertical} 0`};
  margin: ${({ $marginHorizontal }) => `0 ${$marginHorizontal}`};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  margin-top: ${({ $marginTop }) => $marginTop};
  margin-left: ${({ $marginLeft }) => $marginLeft};
  margin-right: ${({ $marginRight }) => $marginRight};
  padding: ${({ $padding }) => $padding};
  padding: ${({ $paddingVertical }) => `${$paddingVertical} 0`};
  padding: ${({ $paddingHorizontal }) => `0 ${$paddingHorizontal}`};
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom};
  padding-top: ${({ $paddingTop }) => $paddingTop};
  padding-left: ${({ $paddingLeft }) => $paddingLeft};
  padding-right: ${({ $paddingRight }) => $paddingRight};
`
