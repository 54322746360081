import {
  BPSizes,
  Colors,
  Palette,
  Rem,
  SiteSpacings,
  Typography,
} from 'commons/Theme'
import Link from 'components/Link/Link'
import styled, { css } from 'styled-components'

export const StyledAvviso = styled.div`
  margin-bottom: ${SiteSpacings.Spacing06.sm}px;
  padding-bottom: ${SiteSpacings.Spacing06.sm}px;
  border-bottom: 1px solid ${Palette.Greyscale[500]};

  &:last-child {
    border-bottom: 0;
  }
`

export const StyledAvvisoBody = styled.div`
  margin: 32px auto 48px;
  /* Disabled as per new Layout - CRQA2A-3782 */
  /* max-width: 1440px; */

  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin: 64px auto 160px;
  }
`

export const StyledAvvisoDate = styled.div`
  font-size: ${Rem(14)};
  color: ${Palette.Greyscale['800']};
  display: inline-block;
  text-transform: capitalize;
  line-height: 1.3;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: ${Rem(16)};
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(18)};
    line-height: 1.35;
  }
`

export const StyledAvvisoDateTemplate = styled.div`
  font-size: ${Rem(16)};
  color: ${Palette.Greyscale['800']};
  display: inline-block;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1.4;
`

const StyledAvvisoCategoria = css`
  font-size: ${Rem(14)};
  color: ${Colors.azure600};
  font-weight: 500;
  display: inline-block;
  margin-right: ${Rem(24)};
  line-height: 1.3;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: ${Rem(16)};
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(18)};
    line-height: 1.35;
  }
`

export const StyledAvvisoCategoriaText = styled.div`
  ${StyledAvvisoCategoria};
`

export const StyledAvvisoCategoriaLink = styled(Link)`
  ${StyledAvvisoCategoria};

  &:hover {
    text-decoration: underline;
  }
`

export const StyledAvvisoCategoriaTemplate = styled(Link)`
  font-size: ${Rem(18)};
  color: ${Palette.Azure['600']};
  font-weight: 600;
  line-height: 1.4;
  display: inline-block;
  margin-right: ${SiteSpacings.Spacing05.sm}px;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledAvvisoCategoryDateWrapper = styled.div`
  margin-bottom: ${SiteSpacings.Spacing04.sm}px;
`

export const StyledAvvisoTitle = styled(Link)`
  ${Typography.Heading03.sm};
  color: ${Palette.Blue['800']};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

export const StyledAvvisoMostraAltro = styled.div`
  text-align: center;
  color: ${Colors.azure600};
  font-size: ${Rem(18)};
  font-weight: 500;
  cursor: pointer;
`

export const StyledFiltro = styled.div`
  display: none;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    display: block;
    min-width: 300px;
  }
`

export const StyledMobileFiltro = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 48px;

  span {
    font-weight: 600;
    font-size: ${Rem(16)};
    line-height: 1.3;
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    display: none;
  }
`

export const StyledFiltroModale = styled.div``
