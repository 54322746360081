import { AtomButton } from 'atoms/AtomButton'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import { eventDataLayer, formatDate, getGA4PageSezione } from 'commons/utils'
import DynamicComponent, {
  ComponentDataModel,
} from 'components/DynamicComponent/DynamicComponent'
import { CaretDownIcon } from 'components/Icons/CaretDownIcon'
import { Icon } from 'components/Icons/Icon'
import Modal from 'components/Modale/Modal'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import { PageDataContext } from 'contexts/PageDataContext'
import { graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../Layout'
import { Grid } from '../commons/Grid'
import { MarginWrapper } from '../commons/MarginWrapper'
import { Colors, StyledH4 } from '../commons/Theme'
import { ModalProps } from '../components/Modale/SearchModal/SearchModal.types'
import { DatePicker } from '../components/UI/DatePicker'
import {
  StyledAvviso,
  StyledAvvisoBody,
  StyledAvvisoCategoriaTemplate,
  StyledAvvisoCategoryDateWrapper,
  StyledAvvisoDateTemplate,
  StyledAvvisoTitle,
  StyledFiltro,
  StyledFiltroModale,
  StyledMobileFiltro,
} from '../pages-style/avvisi.style'
import { PageDataBaseType } from 'models/page-model'
import classNames from 'classnames'
import { HeroImage } from 'components/HeroImage/HeroImage'
import { StyledCategoriaWrapper } from 'pages-style/categoria.style'
import { StyledLabelWrapper } from 'commons/FormComponents/Input.style'
import { StyledGeneralLabel } from 'commons/FormComponents/General.style'
import { Parser } from 'htmlparser2'

function getTitleSubtitleFromPageDescription(htmlString: string) {
  let firstH2Text: string | null = null
  let firstPText: string | null = null

  let isFirstH2Found = false
  let isFirstPFound = false

  const parser = new Parser(
    {
      onopentag(name) {
        if (name === 'h2' && !isFirstH2Found) {
          isFirstH2Found = true
        }

        if (name === 'p' && !isFirstPFound) {
          isFirstPFound = true
        }
      },
      ontext(text) {
        if (isFirstH2Found && firstH2Text === null) {
          firstH2Text = text
        }

        if (isFirstPFound && firstPText === null) {
          firstPText = text
        }
      },
      onclosetag(tagname) {
        if (tagname === 'h2' && isFirstH2Found) {
          isFirstH2Found = false
        }

        if (tagname === 'p' && isFirstPFound) {
          isFirstPFound = false
        }
      },
    },
    { decodeEntities: true }
  )

  parser.write(htmlString)
  parser.end()

  return { title: firstH2Text, subtitle: firstPText }
}

const FiltroInput = (props: FiltroDataProps) => {
  const { fromDate, setFromDate, toDate, setToDate, onFilter } = props

  const date = new Date()
  const dateTwoYearsAgo = new Date(date.setFullYear(date.getFullYear() - 2))

  return (
    <>
      <MarginWrapper $marginBottom="40px">
        <StyledLabelWrapper>
          <StyledGeneralLabel>Da</StyledGeneralLabel>
        </StyledLabelWrapper>
        <DatePicker
          date={fromDate}
          onDateSelect={setFromDate}
          maxDate={toDate}
          minDate={dateTwoYearsAgo}
          locale="it"
          label="Seleziona data"
          withoutDays
        />
      </MarginWrapper>
      <MarginWrapper $marginBottom="40px">
        <StyledLabelWrapper>
          <StyledGeneralLabel>Al</StyledGeneralLabel>
        </StyledLabelWrapper>
        <DatePicker
          date={toDate}
          onDateSelect={setToDate}
          minDate={fromDate || dateTwoYearsAgo}
          locale="it"
          label="Seleziona data"
          withoutDays
        />
      </MarginWrapper>
      <AtomButton label="Applica" onClick={onFilter} />
    </>
  )
}

const FiltroData = (props: FiltroDataProps) => {
  return (
    <StyledFiltro>
      <MarginWrapper $marginBottom="32px">
        <StyledH4 $color="blue800">Filtra per data</StyledH4>
      </MarginWrapper>
      <FiltroInput {...props} />
    </StyledFiltro>
  )
}

const ModaleFiltroData = ({
  showModal,
  onClose,
  ...rest
}: ModalProps & FiltroDataProps) => {
  useEffect(() => {
    if (showModal) {
      eventDataLayer({
        event: 'modale',
        azione: 'modale_impression',
        stream: 'web',
        modale_name: 'filtra_per_data',
        position:
          getGA4PageSezione() === 'www' ? 'generic' : getGA4PageSezione(),
        sezione: getGA4PageSezione(),
      })
    }
  }, [showModal])

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      fullScreen={true}
      title="Filtra per data"
    >
      <StyledFiltroModale>
        <FiltroInput
          {...rest}
          onFilter={() => {
            rest.onFilter()
            onClose()
          }}
        />
      </StyledFiltroModale>
    </Modal>
  )
}

const MobileFiltroData = (props: FiltroDataProps) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <StyledMobileFiltro onClick={() => setShowModal(true)}>
        <span>Filtra per data</span>
        <Icon width="24px" stroke={Colors.azure600} Icon={CaretDownIcon} />
      </StyledMobileFiltro>
      <ModaleFiltroData
        showModal={showModal}
        onClose={() => setShowModal(false)}
        {...props}
      />
    </>
  )
}

const Avviso = ({
  title,
  created,
  url,
  category,
  categoryUrl,
}: AvvisoProps) => {
  return (
    <StyledAvviso>
      <StyledAvvisoCategoryDateWrapper>
        <StyledAvvisoCategoriaTemplate to={categoryUrl}>
          {category}
        </StyledAvvisoCategoriaTemplate>
        <StyledAvvisoDateTemplate>
          {formatDate(created)}
        </StyledAvvisoDateTemplate>
      </StyledAvvisoCategoryDateWrapper>
      <StyledAvvisoTitle to={url}>{title}</StyledAvvisoTitle>
    </StyledAvviso>
  )
}

const pageSize = 10

export default function PageDefaultCategorieArticoliFiltroData(
  props: PageCategoriaArticoliDataProps
) {
  const {
    data: { page },
    pageContext: { title },
  } = props
  const titleSubTitle = getTitleSubtitleFromPageDescription(
    page.description.processed
  )

  const avvisi = page.relationships.a2a_newsite__comunicazioni.sort(
    (a, b) =>
      new Date(b.field_data_pubblicazione).getTime() -
      new Date(a.field_data_pubblicazione).getTime()
  )

  const [allAvvisi, setAllAvvisi] = useState<AvvisoType[]>(avvisi || [])
  const [activeAvvisi, setActiveAvvisi] = useState<AvvisoType[]>([])
  const [inactiveAvvisi, setInactiveAvvisi] = useState<AvvisoType[]>([])
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  function handleOnMostraAltro() {
    const chunkToShow = inactiveAvvisi.slice(0, pageSize)
    setActiveAvvisi((prev) => [...prev, ...chunkToShow])
    setInactiveAvvisi((prev) => [...prev].slice(pageSize))
  }

  function getBeginningOfMonth(date: Date | undefined) {
    return date ? new Date(date.getFullYear(), date.getMonth(), 1) : undefined
  }

  function getEndOfMonth(date: Date | undefined) {
    return date
      ? new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, -1)
      : undefined
  }

  function handleOnApplyFilter() {
    const filteredAvvisi = avvisi.filter((a) => {
      const dataAvviso = new Date(a.field_data_pubblicazione)

      if (fromDate && toDate) {
        return dataAvviso >= fromDate && dataAvviso <= toDate
      } else if (fromDate && !toDate) {
        return dataAvviso >= fromDate
      } else if (!fromDate && toDate) {
        return dataAvviso <= toDate
      } else {
        return true
      }
    })
    setAllAvvisi(filteredAvvisi)
  }

  useEffect(() => {
    setActiveAvvisi(allAvvisi.slice(0, pageSize))
    setInactiveAvvisi(allAvvisi.slice(pageSize))
  }, [allAvvisi])

  return (
    <A2ADataContextProvider>
      <Layout pageTitle={page?.field_meta_tags_taxonomy_fe?.title || title}>
        <Helmet
          bodyAttributes={{
            class: classNames(
              'a2a-template-categorie-filtro-data',
              'a2a-navigation-main',
              'a2a-navigation-full'
            ),
          }}
        >
          {page?.field_meta_tags_taxonomy_fe?.description && (
            <meta
              name="description"
              content={page?.field_meta_tags_taxonomy_fe?.description}
            />
          )}
        </Helmet>

        <CanonicalUrlComponent url={page?.path?.alias} />

        <PageDataContext.Provider
          value={{
            data: page,
          }}
        >
          <HeroImage
            backgroundColor="blue"
            title={titleSubTitle.title ?? title}
            subtitle={titleSubTitle.subtitle ?? undefined}
            breadcrumbLinks={
              page.relationships?.field_breadcrumb_content
                ?.field_link_breadcrumb
                ? [
                    ...page.relationships.field_breadcrumb_content
                      .field_link_breadcrumb,
                    { title: page.name, uri: page.path.alias },
                  ]
                : undefined
            }
          />
          <StyledCategoriaWrapper>
            <StyledAvvisoBody>
              <Grid
                cols={1}
                desktopCols="auto 1fr"
                colsDesktopGap="120px"
                rowsGap="0"
              >
                <FiltroData
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={(d) => setFromDate(getBeginningOfMonth(d))}
                  setToDate={(d) => setToDate(getEndOfMonth(d))}
                  onFilter={handleOnApplyFilter}
                />
                <MobileFiltroData
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={(d) => setFromDate(getBeginningOfMonth(d))}
                  setToDate={(d) => setToDate(getEndOfMonth(d))}
                  onFilter={handleOnApplyFilter}
                />
                <div>
                  {activeAvvisi.length === 0 && (
                    <div>Non ci sono avvisi per il periodo selezionato</div>
                  )}
                  {activeAvvisi.map((a: AvvisoType) => (
                    <Avviso
                      key={a.id}
                      title={a.title}
                      created={a.field_data_pubblicazione}
                      url={a.path.alias}
                      category={a.relationships.field_categoria_article.name}
                      categoryUrl={
                        a.relationships.field_categoria_article.path.alias
                      }
                    />
                  ))}
                  {!!inactiveAvvisi.length && (
                    <AtomButton
                      style={{ margin: 'auto' }}
                      label="Mostra altri avvisi"
                      onClick={handleOnMostraAltro}
                      styleType="ghost"
                    />
                  )}
                </div>
              </Grid>
            </StyledAvvisoBody>
          </StyledCategoriaWrapper>
          {page.relationships?.field_struttura_content_taxonomy &&
            page.relationships.field_struttura_content_taxonomy
              .filter((field) => field.__typename !== 'paragraph__articoli_fe')
              .map((field, i) => (
                <DynamicComponent
                  key={i}
                  component={field.__typename}
                  data={field}
                  related={{
                    parent: undefined,
                    siblings: {
                      before:
                        page.relationships.field_struttura_content_taxonomy[
                          i - 1
                        ] ?? undefined,
                      after:
                        page.relationships.field_struttura_content_taxonomy[
                          i + 1
                        ] ?? undefined,
                    },
                  }}
                />
              ))}
        </PageDataContext.Provider>
      </Layout>
    </A2ADataContextProvider>
  )
}
interface PageCategoriaArticoliDataProps {
  data: {
    page: PageDataItemType
  }
  pageContext: {
    id: string
    drupal_id: string
    title: string
  }
}

interface PageDataItemType extends PageDataBaseType {
  id: string
  drupal_id: string
  description: {
    processed: string
    format: string
  }
  name: string
  path: {
    alias: string
  }
  internal: {
    type: 'taxonomy_term__categorie_articoli_nuovo_sito'
  }
  field_meta_tags_taxonomy_fe?: {
    title?: string
    description?: string
  }
  status: boolean
  relationships: {
    field_breadcrumb_content?: {
      __typename: string
      id: string
      drupal_id: string
      internal: {
        type: string
      }
      parent_type: string
      parent_field_name: string
      field_link_breadcrumb:
        | {
            title: string
            uri: string
          }[]
        | null
    } | null
    field_struttura_content_taxonomy: ComponentDataModel[]
    a2a_newsite__comunicazioni: AvvisoType[]
  }
}

type FiltroDataProps = {
  fromDate: Date | undefined
  setFromDate: (date: Date | undefined) => void
  toDate: Date | undefined
  setToDate: (date: Date | undefined) => void
  onFilter: () => void
}

type AvvisoProps = {
  title: string
  created: Date
  url: string
  category: string
  categoryUrl: string
}

type AvvisoType = {
  id: string
  drupal_id: string
  title: string
  path: {
    alias: string
  }
  field_data_pubblicazione: Date
  relationships: {
    field_categoria_article: {
      name: string
      path: {
        alias: string
      }
    }
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: taxonomyTermCategorieArticoliNuovoSito(id: { eq: $id }) {
      id
      drupal_id
      name
      description {
        processed
        format
      }
      path {
        alias
      }
      internal {
        type
      }
      field_meta_tags_taxonomy_fe {
        description
        title
      }
      status
      relationships {
        field_breadcrumb_content {
          ...BreadcrumbsFe
        }
        # See src/templates/default-categorie-articoli.tsx
        ...TaxonomyTermCategorieArticoliNuovoSitoStruttura
        a2a_newsite__comunicazioni {
          __typename
          id
          drupal_id
          title
          path {
            alias
          }
          created(locale: "it_it")
          field_data_pubblicazione(locale: "it_it")
          field_sottotitolo
          field_ordine_peso
          relationships {
            field_categoria_article {
              __typename
              name
              path {
                alias
              }
            }
          }
        }
      }
    }
  }
`
